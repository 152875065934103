import React from 'react';

import firebase from 'firebase/app';
import 'firebase/firebase';
import { useCollection } from 'react-firebase-hooks/firestore';

import Typography from '@material-ui/core/Typography';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


import IconButton from '@material-ui/core/IconButton';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';




import CustomerDeviceList from './CustomerDeviceList';
import CustomerProductList from './CustomerProductList';
import CustomerTaskList from './CustomerTaskList';
import CustomerCustomeruserList from './CustomerCustomeruserList';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
  },
  addButton: {
    marginTop: theme.spacing(2),
  },
  item: {
    flexGrow: 1,
  },
  break: {
    flexBasis: '100%',
    height: '0',
  }
}));




function CustomerList(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [tab, setTab] = React.useState(0);

  const [devicesValue, devicesLoading, devicesError] = useCollection(
    firebase.firestore().collection('customerdata').doc(props.selectedCustomerId).collection('devices')
    .orderBy(firebase.firestore.FieldPath.documentId())
  );

  const [productsValue, productsLoading, productsError] = useCollection(
    firebase.firestore().collection('customerdata').doc(props.selectedCustomerId).collection('products')
    .orderBy('name')
  );

  const [tasksValue, tasksLoading, tasksError] = useCollection(
    firebase.firestore().collection('customerdata').doc(props.selectedCustomerId).collection('tasks')
    .orderBy('name')
  );

  const [customerusersValue, customerusersLoading, customerusersError] = useCollection(
    firebase.firestore().collection('customerdata').doc(props.selectedCustomerId).collection('customerusers')
    .orderBy(firebase.firestore.FieldPath.documentId())
  );


  const handleTabChange = (event, newValue) => {
    console.log(newValue);
     setTab(newValue);
   };


  return (<div className={classes.root}>
    <div className={classes.item}>
      <Typography variant="h4">
        <IconButton onClick={props.clearSelectedCustomerId}>
          <ChevronLeftIcon />
        </IconButton>
        {props.customerDoc.data().name}
      </Typography>
    </div>
    <div className={classes.break} />
    <div className={classes.item}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        indicatorColor="primary"
        centered
      >
        <Tab label={t('Devices')} />
        <Tab label={t('Products')} />
        <Tab label={t('Tasks')} />
        <Tab label={t('Users')} />
      </Tabs>
      {!devicesLoading && tab === 0 &&
        <CustomerDeviceList selectedCustomerId={props.selectedCustomerId} devicesDocs={devicesValue.docs} />
      }

      {!productsLoading && tab === 1 &&
        <CustomerProductList selectedCustomerId={props.selectedCustomerId} productsDocs={productsValue.docs} tasksDocs={tasksValue.docs} />
      }

      {!tasksLoading && tab === 2 &&
        <CustomerTaskList selectedCustomerId={props.selectedCustomerId} tasksDocs={tasksValue.docs} />
      }

      {!customerusersLoading && tab === 3 &&
        <CustomerCustomeruserList selectedCustomerId={props.selectedCustomerId} customerusersDocs={customerusersValue.docs} />
      }
    </div>
  </div>
  );
}

export default CustomerList;
