import React from 'react';

import firebase from 'firebase/app';
import 'firebase/firebase';
import { useCollection } from 'react-firebase-hooks/firestore';

import { makeStyles } from '@material-ui/core/styles';


import CustomerList from './CustomerList';



const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));




function CustomerApp(props) {
  const classes = useStyles();

  const [customersValue, customersLoading, customersError] = useCollection(
    firebase.firestore().collection('customerdata')
  );

  if (customersError) console.error(customersError);

  props.handleDrawerOpen();

  return (<div className={classes.root}>
      { !customersLoading &&
        <CustomerList customerDocs={customersValue.docs} />
      }
    </div>);

}

export default CustomerApp;
