import React from 'react';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import AiEdgeIcon from '@material-ui/icons/Done';
import AiCloudIcon from '@material-ui/icons/Cloud';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  addButton: {
    marginTop: theme.spacing(2),
  }
}));




function CustomerProductList(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedProductId, setSelectedProductId] = React.useState(null);


  return (<React.Fragment>
    <div className={classes.root}>
      <List>
        {
          _.map(props.productsDocs, (productDoc) => {
            return (
              <ListItem key={productDoc.id} button onClick={() => {setSelectedProductId(productDoc.id)}}>
                <ListItemIcon >
                {
                  _.some(productDoc.data().regions, (region) => { // EdgeMode is off if any associated task has not its aiSettings.isEdgeMode set to true (implying as well if the aiSettings or isEdgeMode are missing)
                    return _.some(region.tasks, (taskPath, taskId) => {
                      return _.find(props.tasksDocs, (o) => {
                        return (o.id===taskId && _.get(o.data(), 'aiSettings.isEdgeMode') !== true)
                      })
                    })
                    // _.get(doc.data, 'aiSettings.isEdgeMode')
                  }) ? <AiCloudIcon /> : <AiEdgeIcon />
                }
                </ListItemIcon>
                <ListItemText primary={productDoc.data().name ? productDoc.data().name : productDoc.id} />
              </ListItem>
            )
            })

        }
      </List>
      { 1===2 && // disab;ed
        <Button
          variant="contained"
          color="primary"
          className={classes.addButton}
          startIcon={<AddIcon />}
        >
          {t('New Product')}
        </Button>
      }
    </div>

    </React.Fragment>
  );
}

export default CustomerProductList;
