import React, {useCallback} from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {useDropzone} from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'

import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Grid from '@material-ui/core/Grid';

import AiEdgeIcon from '@material-ui/icons/Done';
import AiCloudIcon from '@material-ui/icons/Cloud';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import filesize from 'filesize';


const useStyles = makeStyles(theme => {
  console.log(theme);
return ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  addButton: {
    marginTop: theme.spacing(2),
  },
  dropTarget: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme.palette.grey[800],
    borderStyle: 'dashed',
    backgroundColor: theme.palette.grey[600],
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minHeight: '190px',
  },
  fileButton: {
    marginTop: theme.spacing(4),
  },
  dragActive: {
    borderColor: '#2196f3'
  },
  dragAccept: {
    borderColor: theme.palette.primary.main,
  },
  dragReject: {
    borderColor: theme.palette.secondary.main
  },
  uploadIcon: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  progress: {
    width: '100%',
    marginTop: theme.spacing(8),
  },
  dialogDataHeader: {
    marginBottom: theme.spacing(1),
  },
  dialogEditHeader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
})
}
);


function TaskEditDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [uploadProgress, setUploadProgress] = React.useState(null);

  const [errorText, setErrorText] = React.useState([]);

    const handleSwitchIsEdgeMode = () => {
    if (props.taskDoc) {
      let aiSettings = props.taskDoc.data().aiSettings;
      if (aiSettings && aiSettings.aiStoragePath){
        aiSettings.isEdgeMode = !aiSettings.isEdgeMode;
        props.taskDoc.ref.update({
          aiSettings: aiSettings,
          _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        }).then((res) => console.log('edgemode updated'));
      } else {
        setErrorText ((text) =>  _.union(text, [t("Could not enable AI, as none is provided")]));
      }
    }

  }

  const handleSwitchIsDeleted = () => {
    if (props.taskDoc) {
      props.taskDoc.ref.update({
        _isDeleted: !props.taskDoc.data()._isDeleted,
        _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }).then((res) => console.log('disabled updated'));
    }
  }

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    console.log("uploading.. ??")

    console.log(_.size(acceptedFiles))
    console.log(props.selectedCustomerId)
    console.log(props.selectedTaskId)
    console.log(props.taskDoc)

    if (_.size(acceptedFiles) === 1 && props.selectedCustomerId && props.selectedTaskId && props.taskDoc) {
      console.log('Working on the upload...')


      let storageRef = firebase.storage().ref('customerdata/' + props.selectedCustomerId + '/tasks/' + props.selectedTaskId + '/ai/' + uuidv4());
      let uploadTask = storageRef.put(acceptedFiles[0]);
      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on('state_changed', function(snapshot){
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log('Upload is ' + progress + '% done');
        setUploadProgress(progress);

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
          default: {
            console.log('Upload default state:');
            console.log(snapshot.state)
            break;
          }
        }
      }, function(error) {
        console.error(error);
        setErrorText ((text) =>  _.union(text, [t("Upload did not succeed")]));
        // Handle unsuccessful uploads
      }, function() {
        console.log("SUCCESS... lets update our thingy..")
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        setUploadProgress(null);

        props.taskDoc.ref.update(
          {
            aiSettings: {
              aiStoragePath: uploadTask.snapshot.ref.fullPath,
              isEdgeMode: true,
              originFileName: acceptedFiles[0].name,
              originFileType: acceptedFiles[0].type,
              originFileSize: acceptedFiles[0].size,
              uploadTime: firebase.firestore.FieldValue.serverTimestamp(),
              localUploadTime: new Date(),
              uploadUserEmail: firebase.auth().currentUser.email,
              uploadUserUid: firebase.auth().currentUser.uid,
            },
            aiHistory: firebase.firestore.FieldValue.arrayUnion(
              {
                aiStoragePath: uploadTask.snapshot.ref.fullPath,
                isEdgeMode: true,
                originFileName: acceptedFiles[0].name,
                originFileType: acceptedFiles[0].type,
                originFileSize: acceptedFiles[0].size,
                localUploadTime: new Date(),
                uploadUserEmail: firebase.auth().currentUser.email,
                uploadUserUid: firebase.auth().currentUser.uid,
              },
            ),
            _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          }
        ).then(()=>{
          console.log('saved...');
        })
        .catch((error) => {
          console.log('update error')
          console.error(error);
        })
      });
      // uploadTask.then(()=>{
      //   console.log("=---- upoload task then")
      // })
    }

}, [props, t])
  const {getRootProps, getInputProps,  open, isDragActive, isDragAccept, isDragReject} = useDropzone({onDrop, noClick: true, multiple: false, preventDropOnDocument: true, disabled: uploadProgress !== null})
  const {ref, ...rootProps} = getRootProps()

  const handleDialogClose = () => {
    if (uploadProgress === null) {
      props.handleDialogClose();
    } else {
      setErrorText ((text) =>  _.union(text, [t("Please wait until the upload is completed")]));
    }
  }

  const undefinedText = (input) => {
    if (!_.isString(input)) {
      return "";
    } else {
      return input
    }
  }

  const gridName = 2;
  const gridContent = 12 - gridName;

  return (<RootRef rootRef={ref}>
    <Dialog
      {...rootProps}
      open
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth='md'
      >
      <DialogTitle id="form-dialog-title">{props.taskDoc.data().name}</DialogTitle>

      <DialogContent>
      <Typography className={classes.dialogDataHeader} variant="h6">{t('Task Data')}</Typography>
      <Grid container spacing={1}>
        <Grid item xs={gridName}>
          <Typography color="textSecondary">
            {t('id')}
          </Typography>
        </Grid>
        <Grid item xs={gridContent}>
          <Typography>
            {
              props.taskDoc.id
            }
          </Typography>
        </Grid>

        <Grid item xs={gridName}>
          <Typography color="textSecondary">
            {t('path')}
          </Typography>
        </Grid>
        <Grid item xs={gridContent}>
          <Typography>
            {
              props.taskDoc.ref.path
            }
          </Typography>
        </Grid>


        <Grid item xs={gridName}>
          <Typography color="textSecondary">
            {t('AI uploadTime')}
          </Typography>
        </Grid>
        <Grid item xs={gridContent}>
          <Typography>
            {_.get(props.taskDoc.data(), 'aiSettings.uploadTime') &&
              format( _.get(props.taskDoc.data(),'aiSettings.uploadTime').toDate(), "yyyy-MM-dd HH:mm:ss xxx")
            }
          </Typography>
        </Grid>

        <Grid item xs={gridName}>
          <Typography color="textSecondary">
            {t('AI originFileName')}
          </Typography>
        </Grid>
        <Grid item xs={gridContent}>
          <Typography>
            {undefinedText(_.get(props.taskDoc.data(),'aiSettings.originFileName'))}
          </Typography>
        </Grid>

        <Grid item xs={gridName}>
          <Typography color="textSecondary">
            {t('AI originFileSize')}
          </Typography>
        </Grid>
        <Grid item xs={gridContent}>
          <Typography>
            {_.get(props.taskDoc.data(), 'aiSettings.originFileSize') &&
              filesize(_.get(props.taskDoc.data(),'aiSettings.originFileSize'))
            }
          </Typography>
        </Grid>

        <Grid item xs={gridName}>
          <Typography color="textSecondary">
            {t('AI originFileType')}
          </Typography>
        </Grid>
        <Grid item xs={gridContent}>
          <Typography>
            {undefinedText(_.get(props.taskDoc.data(),'aiSettings.originFileType'))}
          </Typography>
        </Grid>

        <Grid item xs={gridName}>
          <Typography color="textSecondary">
            {t('AI aiStoragePath')}
          </Typography>
        </Grid>
        <Grid item xs={gridContent}>
          <Typography>
            {undefinedText(_.get(props.taskDoc.data(),'aiSettings.aiStoragePath'))}
          </Typography>
        </Grid>

      </Grid>

      <Typography className={classes.dialogEditHeader} variant="h6">{t('Edit Task')}</Typography>
      <Typography color="primary" variant="caption">{t('Edits are immediately saved')}</Typography>




          <input {...getInputProps()} />
          <div className={
            clsx(classes.dropTarget, {
              [classes.dragActive]: isDragActive,
              [classes.dragReject]: isDragReject,
              [classes.dragAccept]: isDragAccept,
            })
          }>
          { uploadProgress === null &&
            <React.Fragment>
              <CloudUploadIcon className={classes.uploadIcon}/>

              {
                isDragActive ?
                  isDragAccept ? <Typography>File accepted, just drop it ...</Typography> : <Typography>Drop the file here ...</Typography>
                  : <Typography>Upload a new Ai by Drag 'n' droping the file here, or using 'Select File'. The Ai will become active!</Typography>
              }
              {
                !isDragActive &&
                <Button className={classes.fileButton} variant="contained" color="primary" onClick={open}>Select FIle</Button>

              }
            </React.Fragment>
          }
          { uploadProgress !== null &&
            <React.Fragment>
              <Typography>{t('Uploading...')}</Typography>
              <div className={classes.progress}>
                <LinearProgress variant="determinate" value={uploadProgress} color="primary" />
              </div>
            </React.Fragment>
          }
          </div>

          <div>
          <FormControlLabel
            control={
              <Switch disabled={!_.get(props.taskDoc.data(), 'aiSettings')} checked={_.get(props.taskDoc.data(), 'aiSettings.isEdgeMode') === true} color="primary" onChange={handleSwitchIsEdgeMode} />
            }
            label="isEdgeMode"
          />
          </div>
          <div>
          <FormControlLabel
            control={
              <Switch checked={_.get(props.taskDoc.data(), '_isDeleted') === true} onChange={handleSwitchIsDeleted} />
            }
            label="isDeleted"
          />
          </div>
          { _.size(errorText) > 0 &&
            <div>
              <Typography variant="caption" color="secondary">
                {_.join(errorText)}
              </Typography>
            </div>
          }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  </RootRef>);
}


function CustomerTaskList(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedTaskId, setSelectedTaskId] = React.useState(null);

  const handleDialogClose = () => {
    setSelectedTaskId(null)
  }

  return (<React.Fragment>
    {selectedTaskId &&
      <TaskEditDialog handleDialogClose={handleDialogClose} selectedTaskId={selectedTaskId} selectedCustomerId={props.selectedCustomerId} taskDoc={_.find(props.tasksDocs, (o) => o.id === selectedTaskId)}/>
    }
    <div className={classes.root}>
      <List>
        {
          _.map(props.tasksDocs, (taskDoc) => {
            return (
              <ListItem key={taskDoc.id} button onClick={() => {setSelectedTaskId(taskDoc.id)}}>
                <ListItemIcon >
                {
                  _.get(taskDoc.data(), 'aiSettings.isEdgeMode') === true ? <AiEdgeIcon /> : <AiCloudIcon />
                }
                </ListItemIcon>
                <ListItemText primary={taskDoc.data().name} />
              </ListItem>
            )
            })

        }
      </List>
      { 1===2 && // disab;ed
        <Button
          variant="contained"
          color="primary"
          className={classes.addButton}
          startIcon={<AddIcon />}
        >
          {t('New Task')}
        </Button>
      }
    </div>
    </React.Fragment>
  );
}

export default CustomerTaskList;
