import React from 'react';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import _ from 'lodash';

import CustomerDetail from './CustomerDetail';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  addButton: {
    marginTop: theme.spacing(2),
  }
}));




function CustomerList(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedCustomerId, setSelectedCustomerId] = React.useState(null);


  return (<React.Fragment>
    { selectedCustomerId === null &&
      <div className={classes.root}>
        <List>
          {
            _.map(props.customerDocs, (customerDoc) => {
              return (
                <ListItem key={customerDoc.id} button onClick={() => {setSelectedCustomerId(customerDoc.id)}}>
                  <ListItemText primary={customerDoc.data().name} />
                </ListItem>
              )
              })

          }
        </List>
        <Button
          variant="contained"
          color="primary"
          className={classes.addButton}
          startIcon={<AddIcon />}
        >
          {t('New Customer')}
        </Button>
      </div>
    }
    { selectedCustomerId !== null &&
      <CustomerDetail selectedCustomerId={selectedCustomerId} clearSelectedCustomerId={() => setSelectedCustomerId(null)} customerId={selectedCustomerId} customerDoc={_.find(props.customerDocs, (o) => o.id === selectedCustomerId)}/>
    }
    </React.Fragment>
  );
}

export default CustomerList;
