var React = require('react');

var defaultState = { image: undefined, status: 'loading', urlIdentifier: null };

module.exports = function useImage(url, urlIdentifier, crossOrigin) {
  var res = React.useState(defaultState);
  var image = res[0].image;
  var status = res[0].status;
  var urlIdentifierState = res[0].urlIdentifier;

  var setState = res[1];

  if (urlIdentifier !== urlIdentifierState) { // if our urlIdentifier does not match, make sure we return loading..
    status = 'loading';
  }
  

  React.useEffect(
    function() {
      if (!url) return;
      const img = document.createElement('img');

      function onload() {
        setState({ image: img, status: 'loaded', urlIdentifier: urlIdentifier });
      }

      function onerror() {
        console.log('useImage error');
        setState({ image: undefined, status: 'failed' , urlIdentifier: urlIdentifier });
      }

      img.addEventListener('load', onload);
      img.addEventListener('error', onerror);
      crossOrigin && (img.crossOrigin = crossOrigin);
      img.src = url;

      return function cleanup() {
        img.removeEventListener('load', onload);
        img.removeEventListener('error', onerror);
      };
    },
    [url, crossOrigin, setState, urlIdentifier]
  );

  // return array because it it better to use in case of several useImage hooks
  // const [background, backgroundStatus] = useImage(url1);
  // const [patter] = useImage(url2);
  return [image, status];
};
