import React from 'react';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import _ from 'lodash';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  addButton: {
    marginTop: theme.spacing(2),
  }
}));




function CustomerCustomerusersList(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedCustomeruserId, setSelectedCustomeruserId] = React.useState(null);

  return (<React.Fragment>
    <div className={classes.root}>
      <List>
        {
          _.map(props.customerusersDocs, (customeruserDoc) => {
            return (
              <ListItem key={customeruserDoc.id} button onClick={() => {setSelectedCustomeruserId(customeruserDoc.id)}}>
                <ListItemText primary={customeruserDoc.data().name ? customeruserDoc.data().name : customeruserDoc.id} />
              </ListItem>
            )
            })

        }
      </List>
      <Button
        variant="contained"
        color="primary"
        className={classes.addButton}
        startIcon={<AddIcon />}
      >
        {t('New User')}
      </Button>
    </div>
    </React.Fragment>
  );
}

export default CustomerCustomerusersList;
