export const ACCEPTED_COLOR = '#8BC34A';
export const REJECTED_COLOR = '#EF0000';
export const UNCLASSIFIED_COLOR = '#FFFFFF';
export const HISTORY_LENGTH = 20;

export const REJECTED_KEY = 'x';
export const ACCEPTED_KEY = 'a';

export const REFERENCEIMAGE_DISTANCE = 10;

export const REFERENCEIMAGE_BORDER = 5;

export const DRAWER_WIDTH = 240;
