import React, {useState, useRef, useEffect, forwardRef} from 'react';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';

import useImage from '../base/useImage';


const ImageZoom = forwardRef((props, ref) => {
  const [drawingCanvas, setDrawingCanvas] = useState(null);
  const [drawingContext, setDrawingContext] = useState(null);

  const [markImage] = useImage(props.markImage ? props.markImage : "");
  const [currentImage] = useImage(props.image ?  props.image : "");


  const stageRef = useRef(null);
  const drawingImageRef = useRef(null);
  const layerImageRef = useRef(null);
  const layerMarkRef = useRef(null);

  const zoomLevel = 3;

  let isZooming = false;

  const getPos = () => {
    var pos = stageRef.current.getPointerPosition();

    pos.x = pos.x * (zoomLevel - 1);
    pos.y = pos.y * (zoomLevel - 1);
    // pos.x = pos.x / props.size.scale.x;
    // pos.y = pos.y / props.size.scale.y;

    return pos
  }

  const startZoom = () => {
    if (props.isZoomEnabled) {
      isZooming = true;
      stageRef.current.scale ({
        x: props.size.scale.x * zoomLevel,
        y: props.size.scale.y * zoomLevel,
      });

      zoomMove();
    }

  }

  const zoomMove = () => {
    if (!isZooming) {
      return;
    }

    var pos = getPos();
    stageRef.current.x(-pos.x);
    stageRef.current.y(-pos.y);
    stageRef.current.draw();
  };


  const stopZoom = () => {
    isZooming = false;
    stageRef.current.x(0);
    stageRef.current.y(0);

    stageRef.current.scale ({
      x: props.size.scale.x,
      y: props.size.scale.y,
    });
//    stageRef.current.absolutePosition({x: 0, y: 0});

    stageRef.current.draw();
  }

  useEffect (()=> {
    console.log("SETTING UP IMAGE DRAW");
    let tmpCanvas = document.createElement("canvas");
    tmpCanvas.width = props.crop.width;
    tmpCanvas.height = props.crop.height;
    setDrawingCanvas(tmpCanvas);

    let tmpContext = tmpCanvas.getContext("2d")
    tmpContext.globalCompositeOperation = 'source-over';
    setDrawingContext(tmpContext);

    if (markImage) {
      drawingContext.drawImage(markImage, 0,0);
      if (layerImageRef.current) {
        layerImageRef.current.draw();
      }
      if (layerMarkRef.current) {
        layerMarkRef.current.draw();
      }
    }
  // eslint-disable-next-line
  }, [props.taskImageId, markImage, props.crop.height, props.crop.width]) // NOTE: not including drawingContext here...


  if (markImage) {
    drawingContext.drawImage(markImage, 0,0);
    if (layerImageRef.current) {
      layerImageRef.current.draw();
    }
    if (layerMarkRef.current) {
      layerMarkRef.current.draw();
    }
  }

  let tmpWidth = props.crop.width;
  if (props.size.scale.x) {
    tmpWidth = props.crop.width * props.size.scale.x;
  }

  let tmpHeight = props.crop.height;
  if (props.size.scale.y) {
    tmpHeight = props.crop.height * props.size.scale.y;
  }

  return (
    <Stage
      ref={stageRef}
      width={tmpWidth}
      height={tmpHeight}
      scale={props.size.scale}
      onMousedown={startZoom}
      onTouchstart={startZoom}
      onMouseup={stopZoom}
      onTouchend={stopZoom}
      onMousemove={zoomMove}
      onTouchmove={zoomMove}
    >
      <Layer ref={layerImageRef}>
        <KonvaImage
          image={currentImage}
          width={props.crop.width}
          height={props.crop.height}
          crop={props.crop}
          preventDefault={props.preventDefault}
        />
      </Layer>
      <Layer ref={layerMarkRef}>
        <KonvaImage
          image={drawingCanvas}
          ref={drawingImageRef}
          width={props.crop.width}
          height={props.crop.height}
          preventDefault={props.preventDefault}
          onMouseenter={()=>{stageRef.current.container().style.cursor = props.isZoomEnabled ? 'zoom-in' : 'default';}}
          onMouseleave={()=>{stageRef.current.container().style.cursor = 'default';}}
        />
      </Layer>
    </Stage>
  )
});

export default ImageZoom;
