import React from 'react';

import { Route } from "react-router-dom";

import firebase from 'firebase/app';
import 'firebase/auth';

import { useTranslation } from 'react-i18next';


import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import ClassificationIcon from '@material-ui/icons/Star';
import CustomersIcon from '@material-ui/icons/Business';
import SettingsIcon from '@material-ui/icons/Settings';
import CameraIcon from '@material-ui/icons/PhotoCamera';

import Drawer from '@material-ui/core/Drawer';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import browserhistory from '../Browserhistory';


import {DRAWER_WIDTH} from '../Defines';



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -DRAWER_WIDTH,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },

  appBar: {
    backgroundColor: 'grey',

    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },

}));



function CustomDrawer(props) {
  const classes = useStyles();
  const { t } = useTranslation();


  return (<Drawer
    className={classes.drawer}
    variant="persistent"
    anchor="left"
    open={props.drawerOpen}
    classes={{
      paper: classes.drawerPaper,
    }}
  >
    <div className={classes.drawerHeader}>
      <Route path="/(|classification)" children={({match}) => {return (
        <React.Fragment>
        { match &&
          <IconButton onClick={props.handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        }
        </React.Fragment>
      )}}/>
    </div>
    <Divider />
    <List>
      <Route path="/(|classification)" children={({match}) => {return (
        <ListItem button key={"classification"} onClick={()=>{browserhistory.push('/classification')}} selected={match ? true: false}>
          <ListItemIcon><ClassificationIcon /></ListItemIcon>
          <ListItemText primary={t('Classification')} />
        </ListItem>
      )}} />
      <Route path="/customers" children={({match}) => {return (
        <ListItem button key={"customers"} onClick={()=>{browserhistory.push('/customers')}} selected={match ? true: false}>
          <ListItemIcon><CustomersIcon /></ListItemIcon>
          <ListItemText primary={t('Customers')} />
        </ListItem>
      )}} />
      <Route path="/cameras" children={({match}) => {return (
        <ListItem button key={"cameras"} onClick={()=>{browserhistory.push('/cameras')}} selected={match ? true: false}>
          <ListItemIcon><CameraIcon /></ListItemIcon>
          <ListItemText primary={t('Cameras')} />
        </ListItem>
      )}} />
      <Route path="/settings" children={({match}) => {return (
        <ListItem button key={"settings"} onClick={()=>{browserhistory.push('/settings')}} selected={match ? true: false}>
          <ListItemIcon><SettingsIcon /></ListItemIcon>
          <ListItemText primary={t('Settings')} />
        </ListItem>
      )}} />
    </List>
    <Divider />
    <List>
      <ListItem button key="logout" onClick={() => firebase.auth().signOut()}>
        <ListItemText primary={t('Logout')} />
      </ListItem>
    </List>
  </Drawer>);

}

export default CustomDrawer;
