import React from 'react';

import * as Sentry from '@sentry/browser';


import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';


// let tmpDsn = (window.location.href.indexOf('localhost') > -1) ? null : "https://268ac056414545aba08379da4d12590c@sentry.io/1765944" ;
//
// console.log("DSN:", tmpDsn);
// Sentry.init(
//     {
//       dsn: tmpDsn,
//       relase: 'gixel-client@1.0.0',
//     }
//   );

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
