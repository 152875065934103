import React, {useState, useRef} from 'react';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';


import ClassificationReferenceImageZoomer from './ClassificationReferenceImageZoomer';

import { Stage } from 'react-konva';


import {REFERENCEIMAGE_DISTANCE, REFERENCEIMAGE_BORDER} from '../../Defines';

const useStyles = makeStyles(theme => ({
  sideImageContainer : {
    height: 'calc(100vh - 72px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
}));


function ClassificationReference (props) {
  const classes = useStyles();

  const scrollRef = useRef(null);
  const stageRef = useRef(null);


  const [scrollTopPos, setScrollTopPos] = useState(0);

  let totalAcceptedHeight = 0;


  _.each(props.docs, (doc) => {

    let docData = doc.data();
    let crop = {
        x: docData.region.left,
        y: docData.region.top,
        width: docData.region.right-docData.region.left,
        height: docData.region.bottom-docData.region.top,
      };
    let tmpScale = Math.min(
      (props.stageHeight - REFERENCEIMAGE_BORDER*2)  / crop.height,
      (props.stageWidth - REFERENCEIMAGE_BORDER*2) / crop.width,
    )
    totalAcceptedHeight += REFERENCEIMAGE_DISTANCE + ((REFERENCEIMAGE_BORDER*2 + crop.height) * tmpScale); // REFERENCEIMAGE_DISTANCE is not scaled with the image scale..
  });

  return (
    <div  className={classes.sideImageContainer} ref={scrollRef} onScroll={(ab) => {

      setScrollTopPos(scrollRef.current.scrollTop);
     }
     }>
      <div style={{height: totalAcceptedHeight}} >
        <Stage
          width={props.stageWidth}
          height={props.stageHeight}
          scale={props.stageScale}
          y={-scrollTopPos}
          style={{transform: 'translate(' + 0 + 'px, ' + scrollTopPos + 'px)'}}

          ref={stageRef}

        >
         <ClassificationReferenceImageZoomer
           docs={props.docs}
           stageWidth={props.stageWidth}
           stageHeight={props.stageHeight}
           stageScale={props.stageScale}
           scrollTopPos={scrollTopPos}
           stageRef={stageRef}
           totalAcceptedHeight={totalAcceptedHeight}
          />
        </Stage>
      </div>
    </div>
  );
}

export default ClassificationReference;
