import React from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';

import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  addButton: {
    marginTop: theme.spacing(2),
  },


  dialogDataHeader: {
    marginBottom: theme.spacing(1),
  },
  dialogEditHeader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  dialogEditDiv: {
    marginTop: theme.spacing(3),
  },
  dialogEditSubDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  halfTextField: {
    marginRight: theme.spacing(2),
  }
}));


function DeviceEditDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [errorText, setErrorText] = React.useState([]);

  const handleNameChange = (event) => {
    if (props.deviceDoc) {
      props.deviceDoc.ref.update({
        name: event.target.value,
        _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }).then((res) => {setErrorText([]); console.log('name updated')});
    }
  }

  const handlePreprocessingChange = (type, key, event) => {
    if (props.deviceDoc && type && key) {
      let updateObj = {
        _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      console.log("Key: " + key)
      console.log("Type: " + type)
      switch(type) {
        case 'boolean':
          updateObj['preprocessing.' + key] = !(props.deviceDoc.data().preprocessing[key]);
          console.log(updateObj);
          props.deviceDoc.ref.update(updateObj).then((res) => {setErrorText([]); console.log('preprocessing boolean updated')});
          break;
        case 'number':
          if (!isNaN(event.target.value)) {
            updateObj['preprocessing.' + key] = _.toNumber(event.target.value);
            props.deviceDoc.ref.update(updateObj).then((res) => {setErrorText([]); console.log('preprocessing boolean updated')});
          } else {
            setErrorText ((text) =>  _.union(text, ["Unable to update. Input is not a number. Field: " + key]));
          }
          break;
        case 'string':
          updateObj['preprocessing.' + key] = event.target.value;
          props.deviceDoc.ref.update(updateObj).then((res) => {setErrorText([]); console.log('preprocessing boolean updated')});
          break;
        default:
          setErrorText ((text) =>  _.union(text, ["Unable to update. Currently only boolean, number and string are supported. Field: " + key]));
          break;
      }
    }
  }


  const handleDialogClose = () => {
    props.handleDialogClose();
  }

  const gridName = 2;
  const gridContent = 12 - gridName;

  return (<Dialog
      open
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth='md'
      >
      <DialogTitle id="form-dialog-title">{props.deviceDoc.data().name}</DialogTitle>

      <DialogContent>
      <Typography className={classes.dialogDataHeader} variant="h6">{t('Device Data')}</Typography>
        <Grid container spacing={1}>
          <Grid item xs={gridName}>
            <Typography color="textSecondary">
              {t('id')}
            </Typography>
          </Grid>
          <Grid item xs={gridContent}>
            <Typography>
              {
                props.deviceDoc.id
              }
            </Typography>
          </Grid>

          <Grid item xs={gridName}>
            <Typography color="textSecondary">
              {t('path')}
            </Typography>
          </Grid>
          <Grid item xs={gridContent}>
            <Typography>
              {
                props.deviceDoc.ref.path
              }
            </Typography>
          </Grid>

          <Grid item xs={gridName}>
            <Typography color="textSecondary">
              {t('Trigger')}
            </Typography>
          </Grid>
          <Grid item xs={gridContent}>
            <Typography>
              {
                props.deviceDoc.data().isManualTrigger ? t('manual') : t('automatic')
              }
            </Typography>
          </Grid>

        </Grid>

        <Typography className={classes.dialogEditHeader} variant="h6">{t('Edit Device')}</Typography>
        <Typography color="primary" variant="caption">{t('Edits are immediately saved')}</Typography>
        <div className={classes.dialogEditDiv}>
          <div className={classes.dialogEditSubDiv}>
            <TextField id="device-name" label={t('Device Name')} value={props.deviceDoc.data().name} onChange={handleNameChange} fullWidth />
          </div>
        </div>
        <Typography className={classes.dialogEditHeader} variant="h6">{t('Preprocessing')}</Typography>
        <div className={classes.dialogEditDiv}>
        {
          _.map(_.orderBy(_.map(props.deviceDoc.data().preprocessing, (prepValue, prepKey) => {
            let ret = {}
            ret['key'] = prepKey;
            ret['value'] = prepValue;
            ret['content'] =(
              <div className={classes.dialogEditSubDiv} key={'prep_' + prepKey}>
              { _.isBoolean (prepValue) &&
                <FormControlLabel
                  control={
                    <Switch checked={prepValue === true} color="primary" onChange={_.partial(handlePreprocessingChange, 'boolean', prepKey)} />
                  }
                  label={prepKey}
                />
              }
              { !_.isBoolean (prepValue) && _.isNumber (prepValue) &&
                <TextField id="device-name" label={prepKey} value={prepValue} onChange={_.partial(handlePreprocessingChange, 'number', prepKey)} fullWidth />
              }
              { !_.isBoolean (prepValue) && !_.isNumber (prepValue) && _.isString (prepValue) &&
                <TextField id="device-name" label={prepKey} value={prepValue} onChange={_.partial(handlePreprocessingChange, 'string', prepKey)} fullWidth />
              }

              </div>
            );
            return ret;
          }),
          // order by..
          [(o) => {
            if (_.isBoolean(o.value)) return 1;
            if (_.isString(o.value)) return 2;
            if (_.isNumber(o.value)) return 3;
            return 4
          },
          (o) => o.key]
        ), (e) => e.content)
        }
        </div>

        { _.size(errorText) > 0 &&
          <div>
            <Typography variant="caption" color="secondary">
              {_.join(errorText)}
            </Typography>
          </div>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>);
}


function CustomerDeviceList(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedDeviceId, setSelectedDeviceId] = React.useState(null);

  const handleDialogClose = () => {
    setSelectedDeviceId(null)
  }

  return (<React.Fragment>
    {selectedDeviceId &&
      <DeviceEditDialog handleDialogClose={handleDialogClose} selectedDeviceId={selectedDeviceId} selectedCustomerId={props.selectedCustomerId} deviceDoc={_.find(props.devicesDocs, (o) => o.id === selectedDeviceId)}/>
    }
    <div className={classes.root}>
      <List>
        {
          _.map(props.devicesDocs, (deviceDoc) => {
            return (
              <ListItem key={deviceDoc.id} button onClick={() => {setSelectedDeviceId(deviceDoc.id)}}>
                <ListItemText primary={deviceDoc.data().name ? deviceDoc.data().name : deviceDoc.id} />
              </ListItem>
            )
            })

        }
      </List>
      <Button
        variant="contained"
        color="primary"
        className={classes.addButton}
        startIcon={<AddIcon />}
      >
        {t('New Device')}
      </Button>
    </div>

    </React.Fragment>
  );
}

export default CustomerDeviceList;
