import React, {useEffect, useState} from 'react';

import _ from 'lodash';
import { Router, Route } from "react-router-dom";

import firebase from 'firebase/app';
import 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';

import * as firebaseui from 'firebaseui';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { useTranslation } from 'react-i18next';



import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import Typography from '@material-ui/core/Typography';

import ClassificationApp from './components/classification/ClassificationApp';
import CustomerApp from './components/customers/CustomerApp';
import Drawer from './components/Drawer';

import browserhistory from './Browserhistory';
import clsx from 'clsx';

import {DRAWER_WIDTH} from './Defines';


const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

console.log("SELECTED ENVIRONMENT: " + process.env.REACT_APP_SELECTED_ENV);
console.log(firebaseConfig);

// Initialize Firebase
firebase.initializeApp(firebaseConfig)


// firebase.auth().languageCode = 'de';
firebase.auth().useDeviceLanguage();

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'redirect',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // signInSuccessUrl: '/signedIn',
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false
  },

  // Required to enable one-tap sign-up credential helper.
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ]
};


const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#FFC50B',
      // light: 'red',
      dark: '#FFB300',
      contrastText: '#000',
    },
    secondary: {
      main: '#EF0000',
      // light: 'red',
      dark: '#C62828',
      contrastText: '#000',
    },

  },
});



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: DRAWER_WIDTH,
  },
  updateSnack: {
    backgroundColor: '#FFC50B',
  },
  title:{
    flexGrow: 1,
  }
}));


function App(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isSignedIn, setIsSignedIn] = useState(false);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [transitionCounter, setTransitionCounter] = React.useState(0);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  let uid = "denied";
  if (firebase.auth().currentUser) {
    uid = firebase.auth().currentUser.uid
    // console.log('user logged in... uid: ' + uid);
  }


  useEffect(() => {
    return firebase.auth().onAuthStateChanged(
          (user) => setIsSignedIn(!!user)
      );
  })

  // we use the effect to disable the right click on the page. on the touchscreen a long touch is doing a
  useEffect(() => {
    function contextmenu (event) {
      event.preventDefault()
    }
    window.addEventListener("contextmenu", contextmenu);
    return () => window.removeEventListener("recontextmenusize", contextmenu);
  }, []);

  const [accessValue, accessLoading, accessError] = useCollection(
    firebase.firestore().collectionGroup('customerusers').where('uid', '==', uid)
  );
  if (accessError) console.error(accessError);

  if (!isSignedIn) {
    return (
      <div>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}/>
      </div>
    );
  }
  else {
    if (accessLoading || !accessValue || _.size(accessValue.docs) < 1) {
      return <Typography>{t('Loading User')}</Typography>;
    } else {

      return (
        <div className={classes.root}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router history={browserhistory}>

              <Drawer drawerOpen={drawerOpen} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />
              <main
                className={clsx(classes.content, {
                  [classes.contentShift]: drawerOpen,
                })}
                onTransitionEnd={(e) => { setTransitionCounter((c) => c+1) }}
              >
                <Route path="/(|classification)" render={(props) => <ClassificationApp uid={uid} drawerOpen={drawerOpen} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} transitionCounter={transitionCounter} />} />
                <Route path="/customers" render={(props) => <CustomerApp uid={uid} drawerOpen={drawerOpen} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />} />
              </main>

            </Router>
          </ThemeProvider>
        </div>
      );
    }
  }
}

export default App;
